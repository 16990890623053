import styled from "styled-components";
import Image from "components/Image";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
  width: 90%;
  height: 100%;
  margin: 0 5% 25%;
`;

export const Content = styled.div`
  display: inherit;
  flex-direction: column;
`;
export const StyledImage = styled(Image)`
  margin: 0 auto;
  height: clamp(5rem, 2.0025rem + 9.9917vw, 12.5rem);
  width: clamp(5rem, 2.0025rem + 9.9917vw, 12.5rem);
`;

export const Label = styled.h5`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: 1rem;
  font-size: clamp(0.6875rem, 0.5626rem + 0.4163vw, 1rem);
  line-height: 281%;
  text-align: center;
  color: #382e2b;
  margin: 0 auto;
`;
