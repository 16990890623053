import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const Header2 = styled.h2`
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
`;

export const GalleryImageWrapper = styled.div`
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 300px;
`;

export const GalleryImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

export const Paragraph = styled(ReactMarkdown)`
  margin: 54px 1.75rem 0;

  p {
    font-size: clamp(0.9375rem, 0.7127rem + 0.7494vw, 1.5rem);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
    text-align: center;
    color: #382e2b;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5% 7%;

  @media (min-width: 400px) {
    ${GalleryImageWrapper} {
      height: 400px;
    }
  }

  ${media.tablet} {
    ${Paragraph} {
      display: block;
      max-width: 550px;
      margin: 54px auto 0;
    }

    ${ImagesWrapper} {
      display: grid;
      max-width: 550px;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
    }

    ${GalleryImageWrapper} {
      width: 160px;
      height: 160px;
    }
  }

  ${media.laptop} {
    ${Paragraph} {
      max-width: 920px;
      margin-top: 60px;
    }

    ${ImagesWrapper} {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      max-width: 920px;
    }

    ${GalleryImageWrapper} {
      width: 200px;
      height: 200px;
    }
  }

  ${media.desktop} {
    ${Paragraph} {
      max-width: 1043px;
      margin-top: 60px;
    }

    ${ImagesWrapper} {
      grid-gap: 30px;
      max-width: 1043px;
    }

    ${GalleryImageWrapper} {
      width: 227px;
      height: 227px;
    }
  }
`;
