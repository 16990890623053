import styled from "styled-components";
import Link from "gatsby-link";
import ReactMarkdown from "react-markdown";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";
import LearnMoreBtn from "../../components/LearnMoreBtn";

export const ButtonLink = styled(LearnMoreBtn)`
  width: fit-content;
  height: auto;
`;

export const StyledImage = styled(Image)`
  margin-top: 15%;
  max-width: 70%;
  height: auto;
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;
`;

export const StyledBackgroundImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledParagraph = styled(ReactMarkdown)`
  p {
    font-size: clamp(0.875rem, 0.6002rem + 0.9159vw, 1.5625rem);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 152%;
    color: #382e2b;
    margin: 0;
  }
`;

export const StyledTitle = styled.h2`
  font-size: clamp(1.3125rem, 0.9628rem + 1.1657vw, 2.1875rem);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 126%;
  text-transform: uppercase;
  color: #382e2b;
  margin-top: 0;
  margin-bottom: 14px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 264px;
  margin: 40px 28px 43px;

  ${media.tablet} {
    max-width: 510px;
  }

  ${media.laptop} {
    max-width: 429px;
  }

  ${media.desktop} {
    max-width: 506px;
  }
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 294px;
  overflow: hidden;

  ${media.tablet} {
    min-height: 460px;
  }

  ${media.laptop} {
    width: 50%;
    min-height: 710px;
  }

  ${media.desktop} {
    min-height: 845px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.laptop} {
    flex-direction: row;
  }
`;
