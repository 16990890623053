import styled, { css, keyframes } from "styled-components";
import Image from "components/Image";
import LearnMoreBtn from "components/LearnMoreBtn";

const LastSlideLeftAnimation = keyframes`
  0% { left: 0%; }
  100% { left: -25%; }
`;

const LastSlideOpacityAnimation = keyframes`
  0% { filter: brightness(1); }
  100% { filter: brightness(0); }
`;

const NextSlideAnimation = keyframes`
  0% { right: -100%; }
  100% { right: 0%; }
`;

const ContentAnimation = css`
  .inactive-content {
    z-index: 1;
    opacity: 0;
    transition: all 250ms ease-out 500ms;
  }

  .active-content {
    z-index: 9998;
    opacity: 1;
    transition: all 500ms ease-in 1ms;
  }
`;

const NavigatorAnimation = css`
  .inactive-title {
    opacity: 0.5;
    transition: all 250ms linear 500ms;
  }

  .active-title {
    opacity: 1;
    transition: all 250ms linear 200ms;
  }
`;

export const StyledHeroTitle = styled.h1`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-size: clamp(1.25rem, 0.6977rem + 2.7616vw, 6rem);
  line-height: 125%;
  margin: 0;
`;

export const StyledHeroNavigator = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  ${NavigatorAnimation}
`;

export const StyledHeroButton = styled(LearnMoreBtn)`
  display: inline-block;
  text-align: center;
  width: fit-content;
  height: auto;
  font-size: clamp(0.8rem, -0.875rem + 2.0333vw, 1.25rem);
  margin: 2em 0 0;
`;

export const StyledHeroParagraph = styled.h3`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  display: flex;
  align-items: flex-end;
  text-align: left;
  color: #fff;
  font-size: clamp(0.875rem, 0.6399rem + 1.1756vw, 1.875rem);
  line-height: 150%;
  margin: 0;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeroImage = styled(Image)`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
`;

export const StyledHeroImageContainer = styled.span`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const SlideImageLogo = styled(Image)`
  max-width: 60%;
  display: flex;
  flex-direction: column;
  margin: 0 0 2em;
`;

export const StyledHeroContentContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  flex-direction: column;
  align-items: start;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 70%;
  align-items: flex-start;
  padding: 1em 0;
`;

export const OverlayGroup = styled.div`
  display: flex;
  justify-content: space-between;
  height: 75%;
  width: 80%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9998;
`;

export const StyledContainer = styled.span`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  ${ContentAnimation}

  .inactive-image {
    display: none;
  }

  .initial-image {
    position: absolute !important;
    z-index: 3;
  }

  .last-image {
    z-index: 1;
    position: absolute !important;
    ${css`
      animation: ${LastSlideLeftAnimation} 2000ms ease-out,
        ${LastSlideOpacityAnimation} 1500ms;
    `}
  }

  .next-image {
    z-index: 2;
    position: absolute !important;
    ${css`
      animation: ${NextSlideAnimation} 1000ms ease-in-out;
    `}
  }

  @media screen and (orientation: portrait) {
    ${OverlayGroup} {
      flex-direction: column;
    }
  }

  @media screen and (orientation: landscape) {
    ${OverlayGroup} {
      flex-direction: row;
      ${StyledHeroNavigator} {
        justify-content: flex-start;
        row-gap: clamp(2rem, 1.7674rem + 1.1628vw, 4rem);
      }

      ${ContentWrapper} {
        flex-direction: row;
        justify-content: flex-end;
        max-width: 40%;
        ${StyledHeroContentContainer} {
          height: 100%;
          justify-content: space-between;
          ${SlideImageLogo} {
            max-width: 100%;
            align-self: flex-end;
          }
          ${TextGroup} {
            ${StyledHeroParagraph} {
              text-align: right;
            }
            ${StyledHeroButton} {
              align-self: flex-end;
            }
          }
        }
      }
    }
  }
`;
