import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
//import { media } from "../../components/Theme/default-theme";

export const Header = styled.h2`
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-transform: uppercase;
  color: #382e2b;
  margin-bottom: 30px;
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
    text-align: left;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
    color: #382e2b;
  }
`;

export const FullScreenContainer = styled.div`
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 10%;
  margin: 0 clamp(1.75rem, -2.1717rem + 13.0724vw, 11.5625rem);
  padding: 7% 0;
`;
