import styled, { css } from "styled-components";
import Button from "components/Button";
import Input from "../../components/Input";
import Textarea from "components/Textarea";
import { media } from "../../components/Theme/default-theme";

const ButtonStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  align-self: center;
  font-family: Montserrat, sans-serif;
  font-size: clamp(0.6rem, 2vw, 1rem);
  line-height: 125%;
  font-weight: 325;
  text-align: center;
  color: #ff5f00;
  border: 1px solid #ff5f00;
  background: transparent;
  margin: 52px 0;
`;

export const FooterText = styled.h3`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 180%;
  color: #000;
`;

export const FooterTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
  max-width: 1040px;
`;

export const FormSubmitButton = styled(Button)`
  ${ButtonStyle}
  width: clamp(20%, 5vw, 3rem);
  height: auto;
`;

export const BackButton = styled(Button)`
  ${ButtonStyle}
  width: clamp(7.125rem, 4.9518rem + 7.244vw, 12.5625rem) !important;
  height: auto;
`;

export const FormLegend = styled.h6`
  text-align: right;
  color: #ff6000;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
`;

export const FormTextArea = styled(Textarea)`
  height: 153px;
  width: 100%;
  border-radius: 0;
  margin: 0 0 15px;
  border: 1px solid rgba(56, 46, 43, 0.5) !important;
`;

export const FormTextInput = styled(Input)`
  display: flex;
  flex: 1;
  background: #fff;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
`;

export const FormSubtitle = styled.h3`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(0.8rem, 2vw, 1.6rem);
  line-height: 150%;
  color: #382e2b;
  margin: clamp(0.3125rem, 0.0627rem + 0.8326vw, 0.9375rem) 0;
  text-align: ${({ position = "left" }) => position};
`;

export const FormTitle = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(0.8rem, 4vw, 2.5rem);
  line-height: 125%;
  color: #382e2b;
  margin-top: 130px;
  margin-bottom: clamp(0.9375rem, -0.9359rem + 6.2448vw, 5.625rem);
`;

export const FormGroup = styled.span``;

export const FormContainer = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1040px;
`;

export const FormParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #382e2b;
`;

export const Title = styled.h4`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(0.75rem, 0.5252rem + 0.7494vw, 1.3125rem);
  text-transform: uppercase;
  color: #382e2b;
  width: 100%;
  margin: 0.5rem 0;
`;

export const Container = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  background: #f8f8f8;

  @media (orientation: landscape) {
    ${FormContainer} {
      width: 70%;

      .policy-agreement-field {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 5% 0;
        gap: 15px;
      }
    }

    ${FooterTextContainer} {
      width: 70%;
      ${FooterText}.contact-info {
        color: #382e2b;
        margin: 0;
        text-align: right;
      }
    }
  }

  @media (orientation: portrait) {
    ${FormContainer} {
      width: 95%;

      .policy-agreement-field {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 5% 0;
        gap: 15px;
      }

      ${FormGroup} {
        .flex-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          input {
            margin: 0;
          }
        }
      }
    }
    ${FooterTextContainer} {
      width: 95%;
      ${FooterText}.contact-info {
        color: #382e2b;
        margin: 0;
        text-align: left;
      }
    }
  }

  ${media.tablet} {
    ${FormContainer} {
      width: 70%;
      ${FormGroup} {
        .flex-row {
          display: flex;
          flex-direction: row;
        }
      }
    }
    ${FooterTextContainer} {
      width: 70%;
    }
  }
`;
