//import { fadeIn } from "@racepointenergy/gatsby-theme-sitespark/src/components/Observer";
import styled, { css, keyframes } from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "components/Theme/default-theme";
import Image from "components/Image";
import VideoPlayer from "../VideoPlayer";

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const Video = styled(VideoPlayer)`
  height: 100% !important;
  width: 100% !important;
  cursor: pointer;

  video {
    z-index: 0;
    position: absolute;
    object-fit: ${({ object_fit }) => object_fit} !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const GridTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-size: clamp(1.3125rem, 1.0331rem + 1.3971vw, 2.5rem);
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
`;

export const GridDescription = styled(ReactMarkdown)`
  margin-top: 30px;
  margin-bottom: 45px;

  p {
    margin: 0;
    font-size: clamp(0.9375rem, 0.8051rem + 0.6618vw, 1.5rem);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
    text-align: center;
  }
`;

export const GridFooterLineText = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
`;

export const GridFooterWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 45px;
  padding: 0 20px;
`;

export const GridItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: fit-content;
`;

export const GridItemWrapper = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  margin: 1.5rem 0;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ItemImage = styled(Image)`
  width: 70%;
  height: auto;
  align-self: center;
  img {
    object-fit: ${({ img_fit }) => img_fit ?? "contain"} !important;
  }
`;
export const StyledImage = styled.img`
  object-fit: contain;
`;

export const ItemLabel = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: 25px;
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 100%;
  margin: 14% 7%;
`;

export const CTAButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding: 0 0 3em;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 100%;
  opacity: 0;
  ${({ background, font_color }) => css`
    background: ${background || "#ffffff"};
    color: ${font_color || "#382e2b"};
  `}

  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeIn} 500ms ease-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-delay: 500ms;
    `}

  ${media.tablet} {
    ${ContentContainer} {
      margin: 10%;
      ${GridItemsWrapper} {
        justify-content: center;
        ${GridItemWrapper} {
          flex: 1 0 ${({ item_width }) => `${item_width}%`};
          max-width: ${({ item_width }) => `${item_width}%`};
          margin: 0.5rem;
          ${GridItem} {
            ${ItemLabel} {
              max-width: 100%;
              margin: 25px auto 0;
            }
          }
        }
      }
    }
  }

  ${media.laptop} {
    ${ContentContainer} {
      ${GridItemsWrapper} {
        ${GridItemWrapper} {
          ${GridItem} {
            ${ItemLabel} {
              max-width: 20em;
              margin: 25px auto 0;
            }
          }
        }
      }
    }
  }

  ${media.desktop} {
    ${ContentContainer} {
      max-width: 1681px;
      margin: 10% auto;
    }
  }
`;
