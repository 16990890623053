import styled from "styled-components";
import { media } from "../../components/Theme/default-theme";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";

export const Header = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 325 !important;
  font-size: clamp(1.3125rem, 0.8813rem + 1.5vw, 2.5rem) !important;
  line-height: 125%;
  text-transform: uppercase;
  color: #382e2b;
  text-align: center;
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
    line-height: 150%;
    color: #382e2b;
    text-align: center;
  }
`;

export const TopContainer = styled.div`
  padding: 35px 10px 50px;
`;

export const Background = styled.div`
  background: #e9e9eb;
  margin-top: 7rem;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
`;

export const StyledImage = styled(Image)`
  width: auto;
  max-width: 500px;
  height: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ImagesBackground = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const Label = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(1rem, 0.9001rem + 0.3331vw, 1.25rem);
  line-height: 281%;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
  margin: 20px 0 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (orientation: portrait) {
    ${ImagesBackground} {
      ${ImagesWrapper} {
        flex-wrap: wrap;
        justify-content: space-around;
        ${ItemWrapper} {
          flex: 0 1 51%;
          justify-content: space-between;
        }
      }
    }
  }

  ${media.tablet} {
    ${TopContainer} {
      max-width: 80%;
      margin: 0 auto;
    }
    ${ImagesBackground} {
      ${ImagesWrapper} {
        max-width: 80%;
        margin: 0 auto;
        ${ItemWrapper} {
          flex: 0 1 50%;
        }
      }
    }
  }

  @media (orientation: landscape) {
    ${ImagesBackground} {
      ${ImagesWrapper} {
        ${ItemWrapper} {
          flex: 1;
        }
      }
    }
  }
`;
