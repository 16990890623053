import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";

import Image from "components/Image";
import { media } from "components/Theme/default-theme";
import VideoPlayer from "components/VideoPlayer";

export const Video = styled(VideoPlayer)`
  height: 100% !important;
  width: auto !important;
  cursor: pointer;

  video {
    position: absolute;
    object-fit: ${({ object_fit }) => object_fit} !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding: 5% 0;

  ${media.laptop} {
    width: ${({ widthPercent }) => `${widthPercent || 50}%`};
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ background, font_color }) => css`
    background: ${background ||
    "linear-gradient(180deg, #eeefed 0%, #dcdcdc 100%)"};
    color: ${font_color || "#382e2b"};
  `}
  overflow: hidden;
  z-index: -2;

  ${media.laptop} {
    ${ContentContainer} {
      flex-direction: ${({ reverseMode }) =>
        reverseMode ? "row-reverse" : "row"};
    }
  }
`;

export const StyledImage = styled(Image)`
  display: flex;
  width: auto;
  height: auto;
  margin: 0 5%;

  ${media.laptop} {
    width: 569.59px;
    height: 472.65px;
    ${({ enabledBigImage }) =>
      enabledBigImage &&
      css`
        max-width: 100%;
        max-height: 100%;
      `}
  }

  ${media.desktop} {
    width: 755.45px;
    height: 617.54px;
    ${({ enabledBigImage }) =>
      enabledBigImage &&
      css`
        max-width: 100%;
        max-height: 100%;
      `}
  }
`;

const setMarginTop =
  (value) =>
  ({ marginTopForContent }) =>
    marginTopForContent &&
    css`
      margin-top: ${value}px;
    `;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${setMarginTop(0)}
  max-width: 304px;
  text-align: ${({ contentAlign }) => contentAlign} !important;
  padding: 10% 0;

  ${media.tablet} {
    max-width: 510px;
    ${({ reverseMode }) =>
      reverseMode &&
      css`
        margin-top: 70px;
      `}

    ${setMarginTop(0)}
  }

  ${media.laptop} {
    margin-top: 0;
    max-width: 545px;
    ${setMarginTop(135)}
  }

  ${media.desktop} {
    max-width: 641px;
    ${setMarginTop(20)}
  }
`;

export const StyledTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 14px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 325;
  font-size: 21px;
  line-height: 26px;

  ${media.tablet} {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 24px;
  }

  ${media.desktop} {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 18px;
  }
`;
export const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 25px;

    ${media.tablet} {
      font-size: 20px;
      line-height: 30px;
    }

    ${media.desktop} {
      font-size: 24px;
      line-height: 36px;
    }
  }

  ul,
  ol {
    li {
      margin: 1.5em 0;
      font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
    }
  }
`;

export const ListIcons = styled.div`
  display: flex;
  justify-content: center;
`;
export const StyledIconImage = styled(Image)`
  width: 45px;
  height: 45px;
  margin: 5px 5px 10px;
`;
