import styled from "styled-components";
import Masonry from "react-masonry-css";
import Slider from "react-slick";
import ReactMarkdown from "react-markdown";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const Container = styled.div`
  position: relative;
  background: #f8f8f8;
  padding: 5% 0;

  .masonry-grid {
    display: none;

    ${media.tablet} {
      padding-top: 45px;
      display: flex;
    }
  }
`;

export const StyledMasonry = styled(Masonry)`
  display: none;

  ${media.tablet} {
    display: flex;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 80%;
  margin: 0 auto;
  .slick-track {
    display: flex;
  }

  .slick-slide {
    float: none;
    height: auto;
    display: flex;
  }

  .slick-initialized {
    .slick-slide {
      display: flex;
    }
  }

  ${media.tablet} {
    display: none;
  }
`;

export const StyledDevice = styled.div`
  width: 80%;

  ${media.tablet} {
    max-width: 629px;
    ${({ shiftDown }) =>
      shiftDown &&
      `
      margin-top: 400px;
    `}
  }

  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledSlide = styled.div`
  height: 100%;

  ${StyledDevice} {
    height: 100%;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  max-width: 143px;

  ${media.mobile} {
    max-width: 280px;
  }

  ${media.tablet} {
    margin: 45px;
    max-width: 330px;
  }
  ${media.desktop} {
    max-width: 370px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
  font-size: clamp(1.25rem, 0.8753rem + 1.249vw, 2.1875rem);
`;

export const StyledParagraph = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
    text-align: center;
    color: #382e2b;
    font-size: clamp(0.9375rem, 0.7127rem + 0.7494vw, 1.5rem);
  }
`;
