import styled from "styled-components";
import Checkbox from "components/Checkbox";

export const PolicyLink = styled.a`
  display: inline-block;
  margin-left: 4px;
  color: #ff5f00;
  text-decoration: underline;
`;

export const PolicyContainer = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 36px;
  color: #382e2b;
  margin-bottom: 15px;
`;

export const FormCheckbox = styled(Checkbox)`
  .checkbox-wrapper {
    ${({ is_error }) => is_error && "border: 1px solid red !important;"}
  }
`;
