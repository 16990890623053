import styled from "styled-components";
import { media } from "../../components/Theme/default-theme";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";

export const SpecificationItem = styled.div`
  padding: 50px;
  border-bottom: 1px solid #e0e0e0;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const SpecificationImage = styled(Image)`
  margin-bottom: 16px;
  width: 100%;
  max-width: 50vw;
`;

export const SpecificationTitle = styled.h4`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #382e2b;
  margin: 0;
`;

export const SpecificationSubtitle = styled(ReactMarkdown)`
  p {
    margin-top: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 325;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #382e2b;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  ${media.tablet} {
    flex-direction: row;
    justify-content: center;
    padding: 30px 10px;

    ${SpecificationItem} {
      padding: 25px;
      border-right: 1px solid #e0e0e0;
      border-bottom: none;

      &:last-child {
        border: none;
      }
    }
  }

  ${media.laptop} {
    max-width: 860px;
    margin: 0 auto;

    ${SpecificationTitle} {
      font-size: 50px;
      line-height: 60px;
    }

    ${SpecificationItem} {
      padding: 30px;
    }
  }

  ${media.desktop} {
    max-width: 1041px;
    padding: 80px 10px;

    ${SpecificationItem} {
      padding: 45px;
    }
  }
`;
