import styled from "styled-components";
import Image from "components/Image";
import Link from "gatsby-link";

export const ArticleContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ArticleContentTitle = styled.h2`
  color: #382e2b;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  vertical-align: middle;
  font-weight: normal;
  letter-spacing: 1px;

  @media screen and (max-width: 900px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }
`;

export const ArticleItemSubtitle = styled.div`
  color: #696057;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 20pt;
  vertical-align: middle;
  letter-spacing: 1px;

  @media screen and (max-width: 900px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }
`;

export const ArticleItemContainer = styled.div`
  margin: 1em 0;
  display: flex;
  flex-direction: column;
`;

export const ArticleItemImage = styled(Image)`
  aspect-ratio: 16 / 9;
  width: 220px;
  margin: 0.5em 0;
`;

export const ArticleItemDate = styled.span`
  font-size: 16pt;
  color: #696057;
  font-family: Montserrat, Helvetica, Arial, sans-serif;

  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.25em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.25em;
  }
`;

export const ArticleItemTitle = styled.span`
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 18pt;
  vertical-align: middle;
  margin: 0.5em 0;
  font-weight: normal;

  @media screen and (max-width: 900px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }
`;

export const ArticleItemLink = styled(Link)`
  margin: 10px 0 20px;
  color: #ff5f00;
  text-decoration: none;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 16pt;

  @media screen and (max-width: 900px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5em;
  }

  :hover {
    text-decoration: underline;
  }
`;
